import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router/router';
import videos from './modules/videos';
import sites from './modules/sites';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { videos, sites },
  state: { token: window.localStorage.getItem('token'), username: null },
  getters: {
    isLoggedIn: (state) => {
      return !!state.token;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUsername(state, username) {
      state.username = username;
    },
  },
  actions: {
    login({ commit }, { token, name }) {
      commit('setToken', token);
      commit('setUsername', name);
      window.localStorage.setItem('token', token);
      window.localStorage.setItem('username', name);
    },
    logout({ commit }) {
      commit('setToken', null);
      commit('setUsername', null);
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('username');
      router.push({ name: 'Home' });
    },
  },
});
