import axios from 'axios';
import router from '../../router/router';
import moment from 'moment';

const state = {
  posts: [],
};

const getters = {
  allPosts: (state) => state.posts,
};

const actions = {
  async getPosts({ commit }, categName) {
    const response = await axios
      .get(`https://shrouded-headland-21000.herokuapp.com/api/posts`)
      .then((res) => {
        res.data.posts.map((post) => {
          post.fromNow = moment(post.createdAt).fromNow();
          post.updatedAt = moment(post.updatedAt).format('yyyy-MM-DD ddd');
        });
        return res.data.posts.filter((c) => c.category === categName);
      });
    commit('setPosts', response);
  },

  async deletePost({ commit }, id, category) {
    await axios
      .delete(`https://shrouded-headland-21000.herokuapp.com/api/posts/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      .then(
        category == '비디오 컨텐츠'
          ? router.push({ path: '/project/videos', name: 'Videos' })
          : router.push({
              path: '/project/krepas-worship',
              name: 'Krepas Worship',
            })
      )
      .catch((err) => {
        console.log(err);
      });
    commit('removeVideo', id);
  },

  async newPost({ commit }, post) {
    const response = await axios
      .post(
        `https://shrouded-headland-21000.herokuapp.com/api/posts`,
        {
          title: post.title,
          content: post.content,
          imageUrl: post.imageUrl,
          youtubeUrl: post.youtubeUrl,
          category: post.category,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        }
      )
      .then(
        post.category == '비디오 컨텐츠'
          ? router.push({ path: '/project/videos', name: 'Videos' })
          : router.push({
              path: '/project/krepas-worship',
              name: 'Krepas Worship',
            })
      )
      .catch((err) => {
        console.log(err);
      });
    commit('newPost', response);
  },
};

const mutations = {
  setPosts: (state, posts) => (state.posts = posts),
  newPost: (state, post) => state.posts.unshift(post),
  removeVideo: (state, id) =>
    (state.posts = state.posts.filter((post) => post._id !== id)),
  // updateVideo: (state, updVideo) => {
  //   const index = state.videos.findIndex((todo) => todo.id === updVideo.id);
  //   if (index !== -1) {
  //     state.videos.splice(index, 1, updVideo);
  //   }
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
