<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        후원 / Donation
      </v-card-title>

      <v-card-text
        ><br />
        We appreciate your donation. Your support is crucial to make the Korean
        Rainbow United mission vibrantly and actively alive.
        <br />
        <br />
        You can donate in the following ways:
        <br />

        <ul>
          <li>
            <b>E-transfer</b> :
            <span color="#CE93D8">
              torontogoodtreechurch@gmail.com
            </span>
          </li>
          <li>
            <b
              >Mail a cheque, payable to Good Tree Korean United Church, to the
              following address</b
            >
            :
            <span color="#CE93D8"
              >Good Tree Korean United Church 400 Burnhamthorpe Rd., Etobicoke,
              Toronto, Ontario, M9B 2A8</span
            >
          </li>
        </ul>
        <br />
        <v-icon left color="#CE93D8">mdi-chat-alert</v-icon>
        On the memo line of the cheque, please write
        <span color="#CE93D8">"Donation to Korean Rainbow United". </span
        ><br /><br />
        Note: Since the Korean Rainbow United is not yet a registered charity
        organization, all charitable donations to the Korean Rainbow United are
        required to be sent to Good Tree Korean United Church, which is a
        partner community of the Korean Rainbow United. The tax-deductible
        receipt will be issued by Good Tree Korean United Church.
        <br />
        <br />If you have questions, please contact our financial team by email:
        <a href="mailto:kimyjung@gmail.com" target="_blank"
          >kimyjung@gmail.com</a
        >&nbsp;
        <a href="mailto:lysl1025@gmail.com" target="_blank"
          >lysl1025@gmail.com</a
        >
        <br />
        <br />Thank you! <br />Korean Rainbow United
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('toggle-donation')">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DonationModal',
  props: ['dialog'],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
