<template>
  <v-footer padless color="#CE93D8" class="d-flex justify-center">
    <v-card flat tile color="#CE93D8" class="lighten-1 white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          icon
        >
          <a
            :href="icon.url"
            target="_blank"
            style="text-decoration: none; color:#fff;"
          >
            <v-icon size="40px">
              {{ icon.icon }}
            </v-icon>
          </a>
        </v-btn>
        <v-btn class="mx-4 white--text" icon @click="dialog = true">
          <a style="text-decoration: none; color:#fff;">
            <v-icon size="40px">
              mdi-hand-heart
            </v-icon>
          </a>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        기도, 재능, 그리고 후원으로 함께 해주세요
      </v-card-text>

      <v-divider class="white"></v-divider>

      <v-card-text class="white--text">
        Since 2021.02.12 — <strong>Korean Rainbow United</strong>
        <br />
        <span class="white--text" style="font-size:0.75rem;"
          >Developed by Hendrik Kim</span
        >
      </v-card-text>
      <v-card-text class="white--text my-0 pt-0">
        총 {{ totalViewCount.counts }} 분께서 저희 사이트를 방문해 주셨습니다.
        <br />
        Copyright © 2023 All rights reserved by Korean Rainbow United
      </v-card-text>
    </v-card>
    <DonationModal :dialog="dialog" @toggle-donation="toggleDonationModal" />
  </v-footer>
</template>
<script>
import DonationModal from './shared/DonationModal.vue';

export default {
  components: {
    DonationModal,
  },
  props: ['totalViewCount'],
  data: () => ({
    dialog: false,
    icons: [
      {
        icon: 'mdi-youtube',
        url: 'https://www.youtube.com/channel/UCYTgrNvlp0Cg1onMa9aKf7g',
      },
      {
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/Korean-Rainbow-United-101014552128541',
      },
      // {
      //   icon: 'mdi-hand-heart',
      //   url: 'https://www.facebook.com/Korean-Rainbow-United-101014552128541',
      // },
      // { icon: 'mdi-instagram', url: '' },
    ],
  }),
  methods: {
    toggleDonationModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
