var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","rounded":"0"}},[_c('v-card-text',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{staticClass:"justify-sm-center justify-md-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"height":"60","width":"120","src":require("../assets/images/nav_logo.png")}})],1)],1),_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var home = ref.home;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"100px","text":"","to":"/"}},'v-btn',home,false),on),[_vm._v(" Home ")])]}}])}),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"100px","text":""}},'v-btn',attrs,false),on),[_vm._v(" KRU ")])]}}])},[_c('v-list',_vm._l((_vm.kru),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link},on:{"mouseover":function($event){item.title = item.title_kr},"mouseleave":function($event){item.title = item.title_en}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"100px","text":""}},'v-btn',attrs,false),on),[_vm._v(" Resources ")])]}}])},[_c('v-list',_vm._l((_vm.project),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link},on:{"mouseover":function($event){item.title = item.title_kr},"mouseleave":function($event){item.title = item.title_en}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" "),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"100px","text":""}},'v-btn',attrs,false),on),[_vm._v(" Remembrance ")])]}}])},[_c('v-list',_vm._l((_vm.memory),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"100px","text":""}},'v-btn',attrs,false),on),[_vm._v(" Communication ")])]}}])},[_c('v-list',_vm._l((_vm.counseling),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" "),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)],1)}),1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[(!_vm.isLoggedIn)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var home = ref.home;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/login"}},'v-btn',home,false),on),[_c('v-icon',{attrs:{"color":"black","x-large":""}},[_vm._v("mdi-account-circle")])],1)]}}],null,false,1413271986)}):_vm._e(),(_vm.isLoggedIn)?[(_vm.isLoggedIn)?_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9575cd","x-large":""}},[_vm._v("mdi-account-circle")])],1)]}}],null,false,4274862014)},[_c('v-list',[_c('v-list-item',_vm._g(_vm._b({attrs:{"min-width":"100px","text":"","to":"/dashboard"}},'v-list-item',_vm.home,false),_vm.on),[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-view-dashboard-edit-outline")]),_vm._v(" Dashboard")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-export")]),_vm._v(" Logout")],1)],1)],1)],1):_vm._e()]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }