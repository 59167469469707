import Vue from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$ROOT_API = 'https://shrouded-headland-21000.herokuapp.com/api';

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
