<template>
  <v-app>
    <Navbar v-if="!isMobile" :isLoggedIn="isLoggedIn" />
    <MobileNavbar v-if="isMobile" />
    <v-main style="background:#fafafa;">
      <MainFloatingBtn v-if="!isLoggedIn" :isMobile="isMobile" />
      <v-container style="background:#fff; min-height:90vh; padding:0;">
        <router-view />
      </v-container>
    </v-main>
    <Footer :totalViewCount="totalViewCount" />
  </v-app>
</template>

<script>
import Footer from './components/Footer.vue';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import MainFloatingBtn from './components/MainFloatingBtn';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    MobileNavbar,
    MainFloatingBtn,
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'totalViewCount']),
    isMobile() {
      return this.windowWidth < 960;
    },
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    this.increaseViewCount();
  },

  methods: {
    ...mapActions(['increaseViewCount']),
  },
};
</script>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
}
span {
  font-weight: bold;
  color: #9575cd;
}
</style>
