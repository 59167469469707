<template>
  <div
    :class="isMobile ? 'mobile float-btn text-center' : 'float-btn text-center'"
    style="z-index:10;"
  >
    <div class="my-2">
      <v-btn
        small
        color="#FF0000"
        fab
        dark
        target="_blank"
        href="https://www.youtube.com/channel/UCYTgrNvlp0Cg1onMa9aKf7g"
      >
        <v-icon size="1.75rem"> mdi-youtube </v-icon>
      </v-btn>
    </div>
    <div class="my-2">
      <v-btn
        color="#3b5998"
        fab
        dark
        small
        target="_blank"
        href="https://www.facebook.com/Korean-Rainbow-United-101014552128541"
      >
        <v-icon size="1.75rem"> mdi-facebook </v-icon>
      </v-btn>
    </div>
    <div class="my-2">
      <v-btn small color="#CE93D8" fab dark @click="dialog = true">
        <v-icon size="1.75rem">
          mdi-hand-heart
        </v-icon>
      </v-btn>
    </div>
    <DonationModal :dialog="dialog" @toggle-donation="toggleDonationModal" />
  </div>
</template>

<script>
import DonationModal from './shared/DonationModal.vue';

export default {
  components: { DonationModal },
  props: ['isMobile'],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleDonationModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
.float-btn {
  position: fixed;
  top: 8rem;
  right: 1rem;
}

#create .v-btn--floating {
  position: relative;
}

.mobile {
  top: 25rem;
  right: 1.25rem;
}
</style>
