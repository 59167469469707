<template>
  <div id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item to>
        <v-list-item-content>
          <v-list-item-title class="title">
            Korean Rainbow United
          </v-list-item-title>
          <v-list-item-subtitle>
            캐나다연합교회 무지개연대
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <!-- HOME -->
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>HOME</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- HOME -->
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-list-item link to="/login" v-if="!isLoggedIn">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>LOGIN</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="isLoggedIn">
          <v-list-item link to="/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard-edit-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>DASHBOARD</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>LOGOUT</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#FFFFF0">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          class="mx-2"
          src="../assets/images/nav_logo.png"
          max-height="40"
          max-width="40"
          contain
        ></v-img>
      </router-link>
      <v-toolbar-title style="color:#000;">
        <router-link style="color:#000; text-decoration:none;" to="/"
          >Korean Rainbow United</router-link
        ></v-toolbar-title
      >
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters(['isLoggedIn']),
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.drawer = false;
    },
  },
  data: () => ({
    drawer: null,
    items: [
      {
        action: 'mdi-charity',
        items: [
          { title: 'About KRU', link: '/introduce' },
          { title: 'Vision', link: '/vision' },
          { title: 'Solidarity', link: '/allies' },
          { title: 'Affriming Church', link: '/affirming' },
          // { title: '소개', link: '/introduce' },
          // { title: '비전', link: '/vision' },
          // { title: '연대단체', link: '/allies' },
          // { title: 'Affriming Church', link: '/affirming' },
        ],
        title: 'KRU',
      },
      {
        action: 'mdi-account-group',
        items: [
          { title: 'Translation', icon: 'mdi-account-hard-hat' },
          { title: 'Forum', icon: 'mdi-account-hard-hat' },
          { title: 'Video contents', link: '/project/videos' },
          { title: 'Yookwoodang', link: '/memorial' },
          { title: 'Krepas worship', link: '/project/krepas-worship' },
          // { title: '육우당 추모기도회', link: '/memorial' },
          // { title: '번역자료 (준비중)' },
        ],
        title: 'PROJECT',
      },
      {
        action: 'mdi-flower',
        items: [
          { title: '인물', link: '/great_name' },
          // { title: '사건 (준비중) ' },
          // { title: '국내/국외 (준비중)' },
        ],
        title: 'REMEMBRANCE',
      },
      {
        action: 'mdi-human-greeting-proximity',
        items: [
          { title: 'FAQ', icon: 'mdi-account-hard-hat' },
          { title: '고민나눔', link: '/counsel' },
          // { title: 'Q & A' },
          // { title: 'Talk', link: '/counsel' },
        ],
        title: 'COMMUNICATION',
      },
    ],
    auth: [
      {
        action: 'mdi-export',
        items: [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'logout', link: '/vision' },
        ],
        title: 'logout',
      },
    ],
    right: null,
  }),
};
</script>
