import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/project/videos',
    name: 'Videos',
    component: () => import('../components/post/VideoContent.vue'),
  },
  {
    path: '/project/krepas-worship',
    name: 'Krepas Worship',
    component: () =>
      import('../components/projects/kepasWorship/KepasWorshipIndex.vue'),
  },
  {
    path: '/project/videos/:id',
    name: 'Video Post Detail',
    component: () => import('../components/post/PostDetail.vue'),
  },
  {
    path: '/project/videos/edit/:id',
    name: 'Edit Video Post',
    component: () => import('../components/post/PostUpsertForm.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (!!window.localStorage.getItem('token')) {
        next();
      } else {
        next({ name: 'Login' });
      }
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/counsel',
    name: 'Counsel',
    component: () => import('../views/CounselTrouble.vue'),
  },
  {
    path: '/qna',
    name: 'QnA',
    component: () => import('../views/QnA.vue'),
  },
  {
    path: '/vision',
    name: 'Vision',
    component: () => import('../views/Vision.vue'),
  },
  {
    path: '/introduce',
    name: 'Introduce',
    component: () => import('../views/Introduce.vue'),
  },
  {
    path: '/allies',
    name: 'Allies',
    component: () => import('../views/Allies.vue'),
  },
  {
    path: '/affirming',
    name: 'AffirmingChurch',
    component: () => import('../views/AffirmingChurch.vue'),
  },
  {
    path: '/great_name',
    name: 'GreatName',
    component: () => import('../views/GreatName.vue'),
  },
  {
    path: '/memorial',
    name: 'MemorialPrayerMetting',
    component: () => import('../views/MemorialPrayerMeeting.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (!!window.localStorage.getItem('token')) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
