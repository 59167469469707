import axios from 'axios';

const state = {
  counts: 0,
};

const getters = {
  totalViewCount: (state) => state,
};

const actions = {
  async increaseViewCount({ commit }) {
    const response = await axios
      .get(`https://shrouded-headland-21000.herokuapp.com/api/sites`)
      .then((res) => {
        return res;
      });
    commit('setCounts', response.data.siteViewCount);
  },
};

const mutations = {
  setCounts: (state, view) => (state.counts += view),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
