<template>
  <v-card outlined rounded="0">
    <!-- <v-img
      src="../assets/images/logo.png"
      gradient="to bottom, transparent, rgba(255,255,255,255)"
      aspect-ratio="1.7"
      height="200"
    >
    </v-img> -->
    <v-card-text class="elevation-0">
      <v-row>
        <v-col class="justify-sm-center justify-md-center">
          <router-link to="/">
            <v-img height="60" width="120" src="../assets/images/nav_logo.png">
            </v-img>
          </router-link>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, home }">
              <v-btn min-width="100px" text v-bind="home" v-on="on" to="/">
                Home
              </v-btn>
            </template>
          </v-menu>
          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn min-width="100px" text v-bind="attrs" v-on="on">
                KRU
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in kru"
                :key="index"
                :to="item.link"
                @mouseover="item.title = item.title_kr"
                @mouseleave="item.title = item.title_en"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn min-width="100px" text v-bind="attrs" v-on="on">
                Resources
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in project"
                :key="index"
                :to="item.link"
                @mouseover="item.title = item.title_kr"
                @mouseleave="item.title = item.title_en"
              >
                <v-list-item-title>
                  {{ item.title }}
                  <v-icon>{{ item.icon }}</v-icon></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn min-width="100px" text v-bind="attrs" v-on="on">
                Remembrance
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in memory"
                :key="index"
                :to="item.link"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn min-width="100px" text v-bind="attrs" v-on="on">
                Communication
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in counseling"
                :key="index"
                :to="item.link"
              >
                <v-list-item-title>
                  {{ item.title }} <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col class="d-flex justify-end align-center">
          <v-menu v-if="!isLoggedIn">
            <template v-slot:activator="{ on, home }">
              <v-btn icon v-bind="home" v-on="on" to="/login">
                <v-icon color="black" x-large>mdi-account-circle</v-icon>
              </v-btn>
            </template>
          </v-menu>
          <template v-if="isLoggedIn">
            <v-menu left bottom offset-y offset-x v-if="isLoggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="#9575cd" x-large>mdi-account-circle</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  min-width="100px"
                  text
                  v-bind="home"
                  v-on="on"
                  to="/dashboard"
                >
                  <v-list-item-title>
                    <v-icon left>mdi-view-dashboard-edit-outline</v-icon>
                    Dashboard</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title>
                    <v-icon left>mdi-export</v-icon>
                    Logout</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  computed: mapGetters(['isLoggedIn']),
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
  data: () => ({
    kru: [
      {
        title: 'About KRU',
        title_en: 'About KRU',
        title_kr: '소개',
        link: '/introduce',
      },
      {
        title: 'Vision',
        title_en: 'Vision',
        title_kr: '비전',
        link: '/vision',
      },
      {
        title: 'Solidarity',
        title_en: 'Solidarity',
        title_kr: '연대단체',
        link: '/allies',
      },
      {
        title: 'Affriming Church',
        title_en: 'Affriming Church',
        title_kr: 'Affriming Church',
        link: '/affirming',
      },
      // { title: '소개', link: '/introduce' },
      // { title: '비전', link: '/vision' },
      // { title: '연대단체', link: '/allies' },
      // { title: 'Affriming Church', link: '/affirming' },
    ],
    project: [
      {
        title: 'Translation',
        title_en: 'Translation',
        title_kr: '번역자료',
        icon: 'mdi-account-hard-hat',
      },
      {
        title: 'Forum',
        title_en: 'Forum',
        title_kr: 'Forum',
        icon: 'mdi-account-hard-hat',
      },
      {
        title: 'Video contents',
        title_en: 'Video contents',
        title_kr: '영상자료',
        link: '/project/videos',
      },
      {
        title: 'Yookwoodang',
        title_en: 'Yookwoodang',
        title_kr: '육우당 추모기도회',
        link: '/memorial',
      },
      {
        title: 'Krepas worship',
        title_en: 'Krepas worship',
        title_kr: 'Krepas worship',
        link: '/project/krepas-worship',
      },
      // { title: '육우당 추모기도회', link: '/memorial' },
      // { title: '번역자료 (준비중)' },
    ],
    memory: [
      { title: '인물', link: '/great_name' },
      // { title: '사건 (준비중) ' },
      // { title: '국내/국외 (준비중)' },
    ],
    counseling: [
      { title: 'FAQ', icon: 'mdi-account-hard-hat' },
      { title: '고민나눔', link: '/counsel' },
      // { title: 'Q & A' },
      // { title: 'Talk', link: '/counsel' },
    ],
  }),
};
</script>

<style lang="scss" scoped>
* {
  font-family: 'Gugi', cursive;
}
a {
  text-decoration: none;
}
.v-list-item__title {
  color: #000;
}
</style>
